.title-header {
    font-size: 20px;
    font-weight: bold;
    color: #333; /* Example color */
}
  

table.approvalTbl {
  border-collapse: collapse;
  width: 100%;
}

/* Apply padding, text alignment, and bottom border to th and td elements */
table.approvalTbl > th td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Remove the bottom border from td elements in all rows except the last one */
table.approvalTbl > tbody tr td {
    border-right: none !important;
    border-left: none !important;
}

.scrollable-content {
  height: 85vh;
  overflow-y: auto;
}

/* 
.modal-90w {
  min-width:90%;
} */


.modal-90w {
  width: 90vw !important; /* 90% of viewport width */
  max-width: 90vw !important; /* Set maximum width */
  height: 90vh !important; /* 90% of viewport height */
  max-height: 90vh !important; /* Set maximum height */
}

/* hover profile */
.middle-profilepic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Optional: Adds a semi-transparent overlay */
}

.container-profilepic:hover .middle-profilepic {
  display: flex;
}

.text-profilepic {
  color: #fff;
  font-size: 16px;
}