.cus-color-1 {
    background-color: #062b64 !important;
}

.cus-color-2 {
    background-color: #083982 !important;
}

.cus-color-3 {
    background-color: #e8e8eb !important;
}

.cus-color-4 {
    background-color: #d7e7ff !important;
}

.leave-application-print-modal {
    width: 95% !important;
    max-width: 1000px !important;
}

.create-to-cert-modal {
  z-index: 5;
}

.notification-modal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  position: absolute !important;
  right: 0 !important;
  top: 50px !important;
  width: 80% !important;
}

.login-background-circle {
  width: 100%;
  height: 200%;
  right: 45%;
  position: fixed;
  background-color: #8d9197;
}

.bg-transparent {
  background-color: none;}

.notification-modal>.modal-content {
  border-radius: 0 !important;
}
  
  /*************************************
    Leave Application Modal
  **************************************/
.leave-application-view-modal {
    width: 95% !important;
    max-width: 1000px !important;
}
  
.leave-application-view-modal-body {
    height: 75vh !important;
}
  
  .leave-application-view-modal-body-leave {
    height: 100% !important;
  }
  /*************************************
  **************************************/
  
  /*************************************
    Travel Order Modal
  **************************************/
  .travel-order-view-modal {
    width: 95% !important;
    max-width: 1000px !important;
  }

  .travel-order-create-modal {
    max-width: 1000px !important;
  }
  
  .travel-order-view-modal-body {
    height: 75vh !important;
  }
  
  .travel-order-view-modal-body-to {
    height: 100% !important;
  }

  .travel-order-create-modal-body {
    height: 70vh !important;
  }
  
  .travel-order-create-modal-body-to {
    height: 100% !important;
  }

  /*************************************
  **************************************/
  
  .comment-module-container-title {
    height: 130px !important;
  }
  
  .comment-module-container-comments {
    height: calc(95% - 130px) !important;
  }
  
  .comment-text-area {
    resize: none !important;
  }
  
  .sched-print-modal {
    width: 1000px !important;
    max-width: 1000px !important;
  }
  
  .attend-report-modal {
    width: 1200px !important;
    max-width: 1200px !important;
  }
  
  .travel-order-print-modal {
    width: 1000px !important;
    max-width: 1000px !important;
  }
  
  .create-sched-modal {
    width: 750px !important;
    max-width: 750px !important;
  }
  
  .approve-leave-modal {
    /* width: 550px;
    max-width: 550px; */
  }
  
  .leave-day-input {
    width: 75px;
    max-width: 75px;
  }
  
  .emp-list-container::-webkit-scrollbar {
    display: none;
  }

  .hide-scroll::-webkit-scrollbar {
    display: none;
  }
  
  select.schedule-select:focus, input:focus{
    outline: none;
  }
  
  select.schedule-select{
    cursor: pointer;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  
  select.schedule-select{
    cursor: pointer;
    border: none;
  }
  
  .editSchedButton{
    cursor: pointer;
  }
  
  .sched-rmdp-container .rmdp-input {
    width: 100% !important;
  }

  .rmdp-container {
    width: 100% !important;
  }
  
  table.emp-dtr-table {
    border-collapse: collapse;
  }
  
  table.emp-dtr-table th, td {
    border: 1px solid black !important;
  }
  
  table.emp-attend-report {
    border-collapse: collapse !important;
  }
  
  table.emp-attend-report th, td {
    border: 1px solid black !important;
  }
  
  label.toggle-button-first-item {
    border-radius: .375rem 0rem 0rem .375rem !important;
  }

  input.emp-sched-time-picker {
    width: 100%;
    border: none;
    font-size: 10pt;
    text-align: center;
  }
  
  @media print {
    .page-break {
      page-break-after: always;
    }
  }

  @media (min-width: 768px) { /* md and above */
    .notification-modal {
      width: 250px !important; /* Set your fixed width for md and lg screens */
    }
  }
  
  @media (min-width: 992px) { /* lg and above */
    .notification-modal {
      width: 300px !important; /* Set your fixed width for lg screens */
    }
  }