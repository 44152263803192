html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#wrapper {
  height: 100vh;
}

#sidebar {
  width: 50px;
  height: 100%;
  position : fixed;
  z-index: 2;
}

#page-content {
  width: 100%;
  height: 100%;
  flex: 1;
}

#header {
  height : 50px;
  width : 100%;
}

div.notif-sec {
  height : 50px;
}

/* #footer {
  height : 50px;
  position : fixed;
  bottom : 0px;
  width : 100%;
  left : 50px;
} */

#mainContent {
  width : 100%;
  height : 100%;
}

.page-index-controls {
  top : 50px;
  width : calc(100% + 24px) !important;
  z-index : 2;
}

.query-input {
    width : 150px !important;
}

.tablerows {
  height: '5px'
}

/* Heron's custom CSS*/
.instructions {
  font-size: 0.75rem !important;
  border-radius: 0.5rem !important;
  background: #000 !important;
  color: #fff !important;
  padding: 0.25rem !important;
  position: relative !important;
  bottom: -10px !important;
}

.instructions > svg {
  margin-right: 0.25rem !important;
}

.offscreen {
  position: absolute !important;
  left: -9999px !important;
}

.hide {
  display: none !important;
}

.valid {
  color: limegreen !important;
  margin-left: 0.25rem !important;
}

.invalid {
  color: red !important;
  margin-left: 0.25rem !important;
}

.error-message {
  background-color: lightpink !important;
  color: firebrick !important;
  font-weight: bold !important;
  padding: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #cccccc; /* Adjust color as needed */
}

.item-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  cursor: context-menu;
}

.parentlink {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  cursor: context-menu;
}

.parentlink::before {
    width: 1.25em;
    line-height: 0;
    content: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e);
    transition: transform 0.35s ease;
    transform-origin: .5em 50%;
}

.parentlink[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn.btn-outline-info {
  border-color: transparent;
  color: #000;
  padding-left: 0.1rem;
  font-size: 12px;
}

.btn.btn-outline-info.cur {
  font-weight: bold;
  border-color: transparent;
}

.btn.btn-primary.navB {
  border-color: transparent;
  background-color: transparent;
}

.text-success-lighter {
    color: rgb(2, 188, 11) !important;
}